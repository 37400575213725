import React, { Component } from "react";
import { Column, Button, Modal } from "../../components";
import "./email.scss";
import { getAllLanguageApi } from "../../Services/apiCalls";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import {
  setNotificationAction,
  toggleLoadingAction,
} from "../../Redux/actions";
import {
  DeleteEmail,
  SendEmailApi,
  GetAllEmailsApi,
  UpdateEmailApi,
} from "./apiCalls";
import Select from "react-select";
import DefaultTemplate from "./defaultTemplete";

const emailType = [
  { value: 1, label: "System Generated Email" },
  { value: 2, label: "Direct Email" },
];

class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed1: false,
      isCollapsed2: true,
      SearchByText: "",
      emails: [],
      filteredEmails: [],
      selectedEmail: {
        EmailType: { value: 1, label: "System Generated Email" },
      },
    };
  }
  componentDidMount = () => {
    this.GetAllLanguages();
    this.getAllEmails();
  };

  handleSubmitBtnClick = () => {
    let { selectedEmail } = this.state;

    if (
      !selectedEmail.EmailType ||
      !selectedEmail?.Identifier ||
      !selectedEmail?.Subject
    ) {
      const info = {
        message: "Please Enter the required fields",
        status: "error",
      };
      this.props.setNotificationAction(info);
      this.setState((st) => ({
        ...st,
        invalid: {
          EmailType: !selectedEmail.EmailType ? true : false,
          Identifier: !selectedEmail.Identifier ? true : false,
          Subject: !selectedEmail?.Subject ? true : false,
        },
      }));
      return;
    }
    this.props.toggleLoadingAction(true);
    if (selectedEmail?.Id) {
      UpdateEmailApi(selectedEmail)
        .then((res) => {
          if (res?.success) {
            const info = {
              message: "Email Update successfully",
              status: "success",
            };
            this.props.setNotificationAction(info);
            this.getAllEmails();
          } else {
            const info = {
              message: "Error while updating",
              status: "error",
            };
            this.props.setNotificationAction(info);
          }
        })
        .catch((err) => {
          const info = {
            message:
              err?.response?.data?.message || "Something went wrong try again",
            status: "error",
          };
          this.props.setNotificationAction(info);
        });
    } else {
      const payload = { ...selectedEmail };
      payload.EmailType = selectedEmail.EmailType.value;
      payload.LanguageId = 1;
      payload.CreateDate = new Date();
      payload.ModifiedDate = new Date();
      SendEmailApi(payload)
        .then((res) => {
          if (res?.success) {
            const info = {
              message: "Email add successfully",
              status: "success",
            };
            this.props.setNotificationAction(info);
            this.getAllEmails();
          } else {
            const info = {
              message: "Error while adding",
              status: "error",
            };
            this.props.setNotificationAction(info);
          }
        })
        .catch((err) => {
          const info = {
            message:
              err?.response?.data?.message || "Something went wrong try again",
            status: "error",
          };
          this.props.setNotificationAction(info);
        });
    }
  };

  handleFormFieldChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      selectedEmail: {
        ...this.state.selectedEmail,
        [name]: value,
      },
      invalid: {
        ...this?.state?.invalid,
        [name]: false,
      },
    });
  };
  handleListItemClick = (item) => {
    this.setState({
      selectedEmail: {
        ...item,
      },
      invalid: {},
      isCollapsed2: false,
    });
  };
  handleDeleteListItem = (item) => {
    this.props.toggleLoadingAction(true);
    const payload = {
      Id: item?.Id,
    };
    DeleteEmail(payload)
      .then((res) => {
        if (res.success) {
          this.setState({
            filteredEmails: this.state.filteredEmails.filter(
              (a) => a.Id != item.Id
            ),
            emails: this.state.emails.filter((a) => a.Id != item.Id),
          });
          const info = {
            message: "Email Delete successfully",
            status: "success",
          };
          this.props.setNotificationAction(info);
          this.props.toggleLoadingAction(false);
        }
      })
      .catch((err) => {
        const info = {
          message:
            err?.response?.data?.message || "Something went wrong try again!",
          status: "error",
        };
        this.props.setNotificationAction(info);
        this.props.toggleLoadingAction(false);
      });
  };
  GetAllLanguages = () => {
    getAllLanguageApi()
      .then((res) => {
        let newLanguageList = res.items.map((item) => {
          return {
            ...item,
            value: item.LanguageId,
            label: item.LanguageValue,
          };
        });
        this.setState({
          LanguageList: newLanguageList,
        });
      })
      .catch((err) => console.log(err));
  };
  getAllEmails = () => {
    this.props.toggleLoadingAction(true);
    GetAllEmailsApi()
      .then((res) => {
        this.setState((st) => ({
          ...st,
          emails: res?.items,
          filteredEmails: res?.items,
          isCollapsed2: true,
          selectedEmail: {
            EmailType: this?.state?.selectedEmail?.EmailType,
          },
        }));
        this.props.toggleLoadingAction(false);
      })
      .catch(() => {
        this.setState((st) => ({
          ...st,
          emails: [],
        }));
        this.props.toggleLoadingAction(false);
      });
  };

  onFormSelectChange = (type, selectedOption) => {
    this.setState({
      selectedEmail: {
        ...this.state.selectedEmail,
        [type]: selectedOption,
      },
      invalid: {
        ...this?.state?.invalid,
        [type]: false,
      },
    });
  };

  handleChange(content, editor) {
    this.setState({
      selectedEmail: {
        ...this.state.selectedEmail,
        Body: content,
      },
    });
  }

  render() {
    const { labels } = this.props;
    const {
      isHelpActive,
      isCollapsed1,
      isCollapsed2,
      selectedEmail,
    } = this.state;
    const {
      LanguageId,
      Identifier,
      EmailType,
      Subject,
      Active,
    } = selectedEmail;

    return (
      <div className="email-templates-module columns-wrapper">
        <Column collapse={isCollapsed1} className="col-1">
          <Column.Collapsed
            text={"Emails"}
            onClick={() => this.setState({ isCollapsed1: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-1-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Emails"}</p>
            </div>
            <Button
              className="button-primary AddBtn"
              testId={"open_correlation_form-btn"}
              tooltipButton={labels.ADMIN_OPEN_LOOKUP_CORRELATION_FORM}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              onClick={() =>
                this.setState({
                  selectedEmail: {
                    EmailType: this?.state?.selectedEmail?.EmailType,
                  },
                  isCollapsed2: false,
                })
              }
            />
            <Button
              onClick={() => this.setState({ isCollapsed1: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="first-column admin-save-form">
              <div
                className={`input-select-field ${this?.state?.invalid?.EmailType ? "invalid" : ""
                  }`}
              >
                <h6>{"Select Type"}</h6>
                <Select
                  name="emaiType"
                  className={`select-input   `}
                  placeholder={"Select Type"}
                  value={this?.state?.selectedEmail?.EmailType || ""}
                  onChange={(selectedOption) => {
                    this.setState((st) => ({
                      ...st,
                      filteredEmails: this.state.emails?.filter(
                        (x) => x?.EmailType == selectedOption?.value
                      ),
                      selectedEmail: {
                        EmailType: selectedOption,
                      },
                    }));
                  }}
                  options={emailType || []}
                  clearable={false}
                  searchable={false}
                />
              </div>
              {this?.state?.filteredEmails &&
                this?.state?.filteredEmails?.length > 0 ? (
                <div className="list-wrapper">
                  {this?.state?.filteredEmails &&
                    this?.state?.filteredEmails?.map((item) => (
                      <div
                        className={`list-item ${item?.Id == selectedEmail?.Id ? "active" : ""
                          }`}
                        key={item?.Id}
                        onClick={() => this.handleListItemClick(item)}
                      >
                        <div
                          className="list_item_title"
                        >
                          <p style={{ fontWeight: "600" }}>
                            {item?.Identifier}
                          </p>
                          <p style={{ marginTop: "4px", fontSize: "12px" }}>
                            {item?.Subject}
                          </p>
                        </div>

                      </div>
                    ))}
                </div>
              ) : (
                <div className="no-item-label">
                  <label>{labels.ADMIN_NO_ITEM_TO_DISPLAY}</label>
                </div>
              )}
            </div>
          </Column.Body>
        </Column>
        <Column collapse={isCollapsed2} className="col-1">
          <Column.Collapsed
            text={"Email Detail"}
            onClick={() => this.setState({ isCollapsed2: false })}
            tooltipButton={labels.ADMIN_EXPAND}
            isHelpActive={isHelpActive}
            tooltipHelp={labels.ADMIN_EXPAND_BTN}
            tooltipPlace="left"
            testId={"column-2-expand"}
          />
          <Column.Head>
            <div className="heading">
              <p>{"Email Detail"}</p>
            </div>
            <Button
              onClick={() => this.setState({ isCollapsed2: true })}
              className="collapseBtn"
              tooltipButton={labels.ADMIN_COLLAPSE}
              tooltipHelp={labels.ADMIN_COLLAPSE_HELP}
              tooltipPlace="left"
              isHelpActive={isHelpActive}
              testId={"column-1-collapse"}
            />
          </Column.Head>
          <Column.Body>
            <div className="admin-save-form" style={{ padding: "12px" }}>
              <div className={`input-select-field setHeight50p`}>
                <h6>{"Tyoe"}</h6>
                <Select
                  name="selectedType"
                  className={`select-input`}
                  placeholder={"Select email type"}
                  value={(EmailType && EmailType) || ""}
                  disabled
                  clearable={false}
                  searchable={false}
                  options={emailType || []}
                />
              </div>
              <div
                className={`input-select-field ${this?.state?.invalid?.LanguageId ? "invalid" : ""
                  }`}
              >
                <h6>{"Language"}</h6>

                <Select
                  name="language"
                  className={`select-input `}
                  placeholder={"Select languagee"}
                  value={LanguageId || 1}
                  // onChange={(selectedOption) =>
                  //   this.onFormSelectChange("LanguageId", selectedOption)
                  // }
                  disabled
                  clearable={false}
                  searchable={false}
                  options={this?.state?.LanguageList || []}
                />
              </div>
              <div
                className={`input-field ${this?.state?.invalid?.Identifier ? "invalid" : ""
                  }`}
              >
                <h6>{"Identifier"}</h6>
                <div>
                  <input
                    name={"Identifier"}
                    type={"text"}
                    value={Identifier || ""}
                    disabled={Identifier}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-identifier"}
                  />
                </div>
              </div>

              <div
                className={`input-field ${this?.state?.invalid?.Subject ? "invalid" : ""
                  }`}
              >
                <h6>{"Subject"}</h6>
                <div>
                  <input
                    name={"Subject"}
                    type={"text"}
                    value={Subject || ""}
                    onChange={this.handleFormFieldChange}
                    test-data-id={"input-label-text"}
                  />
                </div>
              </div>

              <div className={`input-field `}>
                <h6>{"Active"}</h6>
                <div className={"list-checkbox"}>
                  <input
                    name={"Active"}
                    type={"checkbox"}
                    value={Active}
                    checked={Active}
                    onChange={(e) => {
                      this.setState({
                        selectedEmail: {
                          ...this.state.selectedEmail,
                          Active: e.target.checked,
                        },
                      });
                    }}
                    test-data-id={"input-label-text"}
                  />
                  <span>{"Active"}</span>
                </div>
              </div>
              <Editor
  apiKey="9kzyakecpjl748s69uqmyypt73wu2anz6m5wij6d68102kx8"
  value={this.state.selectedEmail?.Body || ""}
  init={{
    height: 380,
    menubar: true,
    plugins: [
      'code',  // Make sure the 'code' plugin is included
      'image', 'link', 'lists', 'table', 'media', 'preview', 'fullscreen',
    ],
    toolbar: `
      undo redo | bold italic underline | formatselect | alignleft aligncenter alignright alignjustify | 
      bullist numlist outdent indent | forecolor backcolor | 
      link image media | preview code fullscreen`,  // Add 'code' to toolbar
    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
  }}
  onEditorChange={(content) => {
    this.handleChange(content);
  }}
/>

            </div>
            <div style={{ textAlign: "center" }}>
              <button
                test-data-id={"btn-logout"}
                className="btn btn-login "
                onClick={() => {
                  this.setState((st) => ({ ...st, show: true }));
                }}
              >
                Preview
              </button>
              <button
                test-data-id={"btn-logout"}
                className="btn btn-login "
                onClick={this.handleSubmitBtnClick}
              >
                {selectedEmail?.Id ? "Update Email" : "Add Email"}
              </button>
            </div>
          </Column.Body>
        </Column>
        <Modal
          show={this?.state?.show}
          title="Email Preview"
          className="email-templates-modal"
          onButtonClose={() => {
            this.setState((st) => ({ ...st, show: false }));
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: DefaultTemplate(
                this?.state?.selectedEmail?.Body,
                this?.state?.selectedEmail?.Subject
              ),
            }}
            style={{ color: "#000" }}
          ></div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: state.isLoading,
    labels: state.labels,
  };
}

const actions = {
  setNotificationAction,
  toggleLoadingAction,
};

export default connect(mapStateToProps, actions)(withRouter(EmailTemplates));
